import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-expiredate-window',
  templateUrl: './expiredate-window.component.html',
  styleUrls: ['./expiredate-window.component.css']
})
export class ExpiredateWindowComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
