import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-a-shopping-cart-window',
  templateUrl: './a-shopping-cart-window.component.html',
  styleUrls: ['./a-shopping-cart-window.component.css']
})
export class AShoppingCartWindowComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }


}
