import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser'

@Component({
  selector: 'app-verified-doctor-order-window',
  templateUrl: './verified-doctor-order-window.component.html',
  styleUrls: ['./verified-doctor-order-window.component.css']
})
export class VerifiedDoctorOrderWindowComponent implements OnInit {

  constructor(private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle('/doctororders/new');
  }

}
