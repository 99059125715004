import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PresenceService {
  constructor(private db: AngularFireDatabase) { }

  incrementActiveUsers(pageId: string) {
    const counterRef = this.db.object(`activeUsers/${pageId}`);
    counterRef.query.ref.transaction((currentCount) => {
      return (currentCount || 0) + 1;
    });
  }

  decrementActiveUsers(pageId: string) {
    const counterRef = this.db.object(`activeUsers/${pageId}`);
    counterRef.query.ref.transaction((currentCount) => {
      return (currentCount || 1) - 1;
    });
  }

  getActiveUsersCount(pageId: string) {
    return this.db.object(`activeUsers/${pageId}`).valueChanges().pipe(
      map((count) => {
        return count || 0;
      })
    );
  }

  updateActiveUsers(previousPageId: string, currentPageId: string) {
    if (previousPageId) {
      this.decrementActiveUsers(previousPageId);
    }

    this.incrementActiveUsers(currentPageId);
  }
}