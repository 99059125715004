import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bill-window',
  templateUrl: './bill-window.component.html',
  styleUrls: ['./bill-window.component.css']
})
export class BillWindowComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
