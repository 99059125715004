import { AuthService } from './../auth.service';
import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSnackBarModule, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
   templateUrl: './login.component.html',
   styleUrls: ['./login.component.css']
})
export class LoginComponent {

  constructor(
    public authService : AuthService, 
    private sankBar : MatSnackBar, 
    private router : Router,
    private titleService: Title
  ){}
  
  ngOnInit () {
    this.titleService.setTitle('/login');
  }

  async onLogin(form:  NgForm){
    if(form.invalid){
      this.sankBar.open("Please enter valid data", 'Close');
      return;

    }
    this.sankBar.open("Logging in... Please wait ", 'Close');
    await this.authService.login(form.value.email,form.value.password).then(result => {
      this.router.navigate(['/']);
    }).catch(error => {
      this.sankBar.open("Could not login, check your login credentials", 'Close');
    });
  };

};
