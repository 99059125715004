import { SalesInteractionService } from './../../../a-pointofsale-window/sales-interaction.service';
import { Component, OnInit } from '@angular/core';
import * as tf from '@tensorflow/tfjs';

@Component({
  selector: 'app-prediction-report-items',
  templateUrl: './prediction-report-items.component.html',
  styleUrls: ['./prediction-report-items.component.css']
})
export class PredictionReportItemsComponent implements OnInit {



  constructor( ) { }

  ngOnInit() {



  }



}
