import { AuthService } from './auth.service';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor{

  constructor(private authService :AuthService){}

  intercept(request: HttpRequest<any>, next: HttpHandler  ){
    const authToken = this.authService.getToken();
    const proxyUrl = 'https://pharmacare-proxy.gustavpersson.dev';
    const authRequest = request.clone({
      setHeaders: {
        "authorization": "Bearer " + authToken,
        // "Content-Type": "application/json; charset=utf-8"
      },
      url: proxyUrl + request.url
    });
    return next.handle(authRequest);
  }
}
