import { AuthService } from 'src/app/auth/auth.service';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { PresenceService } from './presence.service';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'pro';
  currentPageTitle: string;
  activeUsersCount: { [key: string]: number } = {};
  activeUsersCountSubscription: Subscription;
  showMenu = true;
  showSignup = true;
  showShoppingcart = true;
  showDoctorLogin = true;
  showDoctorSignup = true;

  constructor(
    private router: Router,
    private authService: AuthService,
    private presenceService: PresenceService,
    private titleService: Title
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.showMenu = event.url !== "/login";
        this.showSignup = event.url !== "/signup";
        this.showShoppingcart = event.url !== "/shoppingcart";
        this.showDoctorLogin = event.url !== "/doctorLogin";
        this.showDoctorSignup = event.url !== "/doctorSignup";
      }
    });
  }

  ngOnInit() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        let previousPageTitle = this.currentPageTitle;

        if (previousPageTitle){
          previousPageTitle = previousPageTitle.replace('/', '⌄');
        }
        
        this.currentPageTitle = event.url.substr(1).replace('/', '⌄');

        if (this.currentPageTitle === '') {
          this.currentPageTitle = 'main';
        }
  
        this.presenceService.updateActiveUsers(previousPageTitle, this.currentPageTitle);
  
        this.activeUsersCountSubscription = this.presenceService.getActiveUsersCount(this.currentPageTitle)
          .subscribe((count: number) => {
            this.activeUsersCount[this.currentPageTitle] = count;
          });
      });
  
    window.addEventListener('beforeunload', this.beforeUnloadHandler);
  }

  ngOnDestroy() {
    this.presenceService.decrementActiveUsers(this.currentPageTitle);

    if (this.activeUsersCountSubscription) {
      this.activeUsersCountSubscription.unsubscribe();
    }

    window.removeEventListener('beforeunload', this.beforeUnloadHandler);
  }

  beforeUnloadHandler = (event) => {
    this.presenceService.decrementActiveUsers(this.currentPageTitle);
  };
}