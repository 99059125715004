import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-check-out-window',
  templateUrl: './check-out-window.component.html',
  styleUrls: ['./check-out-window.component.css']
})
export class CheckOutWindowComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
