import { from } from 'rxjs';
import { SalesInteractionService } from './../a-pointofsale-window/sales-interaction.service';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import * as tf from '@tensorflow/tfjs';

@Component({
  selector: 'app-a-prediction-report-window',
  templateUrl: './a-prediction-report-window.component.html',
  styleUrls: ['./a-prediction-report-window.component.css']
})
export class APredictionReportWindowComponent implements OnInit {


  constructor(private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle('/predictionreport');
  }





}
