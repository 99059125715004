import { AuthService } from './../auth.service';
import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material';

@Component({
   templateUrl: './signup.component.html',
   styleUrls: ['./signup.component.css']
})
export class SignupComponent {

  constructor(public authService : AuthService, private router : Router, private titleService: Title, private sankBar : MatSnackBar){}

  ngOnInit() {
    this.titleService.setTitle('/signup');
  }

  async onSignup(form:  NgForm){

    if(form.invalid){
      return;
    }
    console.log(form.value.role)
    this.authService.createUser(form.value.name,form.value.contact,form.value.nic,form.value.email,form.value.password,form.value.role).then(async response => {
      await this.authService.login(form.value.email, form.value.password);
      this.router.navigate(['/']); 
    }).catch(error => {
      this.sankBar.open(`Could not create user, error:  ${error.message}`, "Close");
    });
  };

};
