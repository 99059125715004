import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-to-bill',
  templateUrl: './add-to-bill.component.html',
  styleUrls: ['./add-to-bill.component.css']
})
export class AddToBillComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
