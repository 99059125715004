import { NgForm } from '@angular/forms';
import { Component } from '@angular/core';
import { AuthDoctorUserService } from '../authDoctorUser.service';
import { Router } from '@angular/router';
@Component({
  templateUrl: './doctorLogin.component.html',
  styleUrls: ['./doctorLogin.component.css']
})
export class DoctorLoginComponent{

  constructor(private authDoctorUserService : AuthDoctorUserService, private router: Router){}

  onDoctorLogin(form:NgForm){
    if(form.invalid){
      return;
    }
    this.authDoctorUserService.login(form.value.email,form.value.password);
    this.router.navigate(['/']);
  }

}
