import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-about-to-finish-window',
  templateUrl: './about-to-finish-window.component.html',
  styleUrls: ['./about-to-finish-window.component.css']
})
export class AboutToFinishWindowComponent implements OnInit {

  constructor(private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle('/outofstock/abouttofinish');
  }

}
